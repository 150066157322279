import {JournalText, GenderMale, GenderFemale, PatchQuestion, Asterisk, PencilSquare, PrinterFill, PersonFill, PersonFillAdd, HouseFill, BuildingsFill, Gear, QuestionCircle} from 'react-bootstrap-icons';
const icons = {JournalText:JournalText, GenderMale:GenderMale, GenderFemale:GenderFemale, PatchQuestion:PatchQuestion, Asterisk:Asterisk, PencilSquare:PencilSquare, PrinterFill:PrinterFill, PersonFill:PersonFill, PersonFillAdd:PersonFillAdd, HouseFill:HouseFill, BuildingsFill, Gear:Gear, QuestionCircle:QuestionCircle};

function Icon({iconName, ...props}){
    const BootStrapIcon = icons[iconName];
    return (
        <BootStrapIcon {...props}/>
    );
}

export default Icon;
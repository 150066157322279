import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import CountrySelect from "./CountrySelect";
import { useState, useRef, useEffect } from 'react';
import Icon from './Icon';
import { FOEs, POEs, VisitTypes, Insurances } from './Constants'
import { Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RenderPDF from './RenderPDF'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.locale("de");

function LabeledInput({ label, ...props }) {
  return (
    <FloatingLabel controlId={"floating" + label} label={label}>
      <Form.Control {...props} placeholder="" />
      <Form.Control.Feedback type="invalid">
        {props.error}
      </Form.Control.Feedback>
    </FloatingLabel>
  );
}

function ChangePatientCardHeader({ icon, label }) {

  return (
    <Card.Header className="edit-patient-card-header">
      <Stack direction="horizontal" gap={3}>
        <Icon iconName={icon} size={32} className="text-muted" />
        <div className="text-muted">
          {label}
        </div>
      </Stack>
    </Card.Header>
  );
}

function ChangePatient({patientInitial, onCancel, onSave}) {

  return (
    <Formik
      validationSchema={Yup.object({
        sex : Yup.string()
          .required("Geschlecht wählen"),
        surname : Yup.string()
          .required("Nachname erforderlich"),
        firstname : Yup.string()
          .required("Vorname erforderlich"),
        dob : Yup.date()
          .typeError("Kein gültiges Datum")
          .required("Geburtsdatum erforderlich")
          .max(new dayjs(), "Geburtsdatum liegt in der Zukunft")
          .min(new dayjs("1800-01-01"), "Geburtsdatum liegt zu weit zurück"),
        street : Yup.string()
          .required("Straße erforderlich"),
        housenumber : Yup.string()
          .required("Hausnummer erforderlich"),
        zip : Yup.string()
          .required("Postleitzahl erforderlich"),
        city : Yup.string()
          .required("Stadt erforderlich"),
        date : Yup.date()
          .typeError("Kein gültiges Datum")
          .required("Aufnahmedatum erforderlich"),
        time : Yup.date()
          .typeError("Keine gültige Uhrzeit")
          .required("Aufnahmezeit erforderlich"),
        insurance : Yup.string()
          .required("Versicherung erforderlich, für Selbstzahler \"Selbstzahler\" eingeben"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSave(values);
      }}
      initialValues={patientInitial}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        setFieldValue,
        errors,
      }) => (    
      <Form noValidate onSubmit={handleSubmit}>
        {/* Patient */}
        <Card className="m-2">
          <ChangePatientCardHeader icon="PersonFill" label="Angaben zur Person" />
          <Card.Body>
            <Row>
              <Col sm={12} md={2} className="mb-2">
                <FloatingLabel controlId="floatingSex" label="Geschlecht">
                  <Form.Select
                    placeholder=""
                    autoFocus
                    value={values.sex}
                    name="sex"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.sex && !errors.sex}
                    isInvalid={touched.sex && !!errors.sex}
                    >
                    <option value=""></option>
                    <option value="m">männlich</option>
                    <option value="w">weiblich</option>
                    <option value="u">unbekannt</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.sex}
                  </Form.Control.Feedback>
                  </FloatingLabel>                
                </Col>
                <Col sm={12} md={4} className="mb-2">
                  <LabeledInput 
                    label="Nachname"
                    name="surname"
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.surname && !errors.surname}
                    isInvalid={touched.surname && !!errors.surname}
                    error={errors.surname}
                  />
                </Col>
              <Col sm={12} md={3} className="mb-2">
                <LabeledInput 
                  label="Vorname"
                  name="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.firstname && !errors.firstname}
                  isInvalid={touched.firstname && !!errors.firstname}
                  error={errors.firstname}
                />
              </Col>
              <Col sm={12} md={3} className="mb-2">
                <LabeledInput
                  label="Geburtsdatum"
                  name="dob"
                  type="date"
                  defaultValue={values.dob.format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setFieldValue("dob", dayjs(e.target.value));
                  }}                  
                  onBlur={handleBlur}
                  isValid={touched.dob && !errors.dob}
                  isInvalid={touched.dob && !!errors.dob}
                  error={errors.dob}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Adress */}
        <Card className="m-2">
          <ChangePatientCardHeader icon="HouseFill" label="Adresse" />
          <Card.Body>
            <Row>
              <Col sm={12} className="mb-2">
                <LabeledInput
                  label="c/o"
                  type="input"
                  name="careof"
                  value={values.careof}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={10} className="mb-2">
                <LabeledInput
                  label="Straße"
                  type="input"
                  value={values.street}
                  name="street"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.street && !errors.street}
                  isInvalid={touched.street && !!errors.street}
                  error={errors.street}

                />
              </Col>
              <Col sm={12} md={2} className="mb-2">
                <LabeledInput
                  label="Hausnummer"
                  type="input"
                  onChange={handleChange}
                  value={values.housenumber}
                  name="housenumber"
                  onBlur={handleBlur}
                  isValid={touched.housenumber && !errors.housenumber}
                  isInvalid={touched.housenumber && !!errors.housenumber}
                  error={errors.housenumber}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={2} className="mb-2">
                <CountrySelect 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="country"
                  value={values.country}
                />
              </Col>
              <Col sm={4} md={2} className="mb-2">
                <LabeledInput
                  label="Postleitzahl"
                  type="input"
                  value={values.zip}
                  onChange={handleChange}
                  name="zip"
                  onBlur={handleBlur}
                  isValid={touched.zip && !errors.zip}
                  isInvalid={touched.zip && !!errors.zip}
                  error={errors.zip}
                />
              </Col>
              <Col sm={8} md={8} className="mb-2">
                <LabeledInput
                  label="Stadt"
                  type="input" 
                  value={values.city}
                  onChange={handleChange}
                  name="city"
                  onBlur={handleBlur}
                  isValid={touched.city && !errors.city}
                  isInvalid={touched.city && !!errors.city}
                  error={errors.city}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} className="mb-2">
                <LabeledInput
                  label="Telefon"
                  type="input"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Insurance */}
        <Card className="m-2">
          <ChangePatientCardHeader icon="BuildingsFill" label="Versicherung" />
          <Card.Body>
            <Row>
              <Col sm={12} md={6} className="mb-2">
                <LabeledInput
                  label="Versicherung"
                  type="insurance"
                  value={values.insurance}
                  onChange={handleChange}
                  name="insurance"
                  onBlur={handleBlur}
                  isValid={touched.insurance && !errors.insurance}
                  isInvalid={touched.insurance && !!errors.insurance}
                  error={errors.insurance}
                  list="insuranceList"              
                />  
                <datalist id="insuranceList">
                  {Insurances.map((i) => {
                    return (
                      <option key={i} value={i}>{i}</option>
                    )
                  })}
              </datalist>
              </Col>
              <Col sm={12} md={6} className="mb-2">
                <LabeledInput 
                  label="Versichertennummer"
                  type="input"
                  name="insuranceNumber"
                  value={values.insuranceNumber}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Case */}
        <Card className="m-2">
          <ChangePatientCardHeader icon="JournalText" label="Falldaten" />
          <Card.Body>
            <Row className="mb-3">
              <Col sm={12} md={4} className="mb-2">
                <FloatingLabel controlId="floatingVisitType" label="Besuchsart">
                  <Form.Select 
                    placeholder=""
                    value={values.visitType}
                    name="visitType"
                    onChange={handleChange}
                    >
                    {VisitTypes.map(oe => {
                      return (
                        <option value={oe} key={oe}>{oe}</option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col  sm={12} md={4} className="mb-2">
                <FloatingLabel controlId="floatingOE" label="Pflegerische OE">
                  <Form.Select
                    placeholder=""
                    value={values.poe}
                    name="poe"
                    onChange={handleChange}
                  >
                    {POEs.map(oe => {
                      return (
                        <option value={oe} key={oe}>{oe}</option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col sm={12} md={4} className="mb-2">
                <FloatingLabel controlId="floatingOE" label="Fachliche OE">
                  <Form.Select
                    placeholder=""
                    value={values.foe}
                    name="foe"
                    onChange={handleChange}
                    >
                    {FOEs.map(oe => {
                      return (
                        <option value={oe} key={oe}>{oe}</option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4} className="mb-2">
                <LabeledInput 
                  label="Fallnummer" 
                  value={values.caseno}
                  disabled
                />
              </Col>
              <Col sm={12} md={4} className="mb-2">
                <LabeledInput
                  label="Aufnahmedatum"
                  type="date"
                  defaultValue={values.date.format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setFieldValue("date", dayjs(e.target.value));
                  }}
                  name="date"
                  onBlur={handleBlur}
                  isValid={touched.date && !errors.date}
                  isInvalid={touched.date && !!errors.date}
                  error={errors.date}
                />
              </Col>
              <Col sm={12} md={4} className="mb-2">
                <LabeledInput
                  label="Aufnahmezeit"
                  type="time"
                  defaultValue={values.time.format("HH:mm")}
                  onChange={(e) => {
                    setFieldValue("time", new dayjs(e.target.value, "HH:mm"));
                  }}
                  name="time"
                  onBlur={handleBlur}
                  isValid={touched.time && !errors.time}
                  isInvalid={touched.time && !!errors.time}
                  error={errors.time}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Stack gap={3} className="m-3 justify-content-end" direction="horizontal">
          <Button variant="danger" onClick={() => onCancel()}>Abbrechen</Button>
          <Button variant="success" type="submit">Speichern</Button>
        </Stack>
      </Form>)}
    </Formik>
  );
}

function RelativeTimer({time, updateInterval}){
  let [relaTime, setRelaTime] = useState(time.fromNow())
  
  useEffect(() => {
    const interval = setInterval(() => setRelaTime(time.fromNow()), updateInterval);
    return () => {
      clearInterval(interval);
    };
  }, [time, updateInterval]);

  return (
    <>
      {relaTime}
    </>
  );
}

function PatientCard({patient, disabled, selected, onEdit, onPrint}){
  const date = patient.date;
  const time = patient.time;
  const datetime = date.second(time.second()).minute(time.minute()).hour(time.hour());

  return (
    <Card className={"mb-3 mt-3 patient-card" + (disabled?" patient-card-disabled":"") + (selected?" patient-card-selected":"")}>
      <Card.Header className="d-flex justify-content-between">
        <span className="font-weight-bold">
          <Icon iconName="JournalText" size={19}/> {patient.caseno} 
        </span>
        <small className="text-muted"><RelativeTimer time={datetime} updateInterval={60000}/></small>
      </Card.Header>
      <Card.Body>
        <p>
          <Icon iconName="PersonFill"/>
          <span> {patient.surname} </span>
          <small className="text-muted">{patient.firstname} </small>
          <Icon 
            iconName={patient.sex==="m"?"GenderMale":patient.sex==="w"?"GenderFemale":"PatchQuestion" }
            size={18} 
            className="text-muted"
          />
        </p>
        <p>
          <Icon iconName="Asterisk"/>
          <span> {patient.dob.format("DD.MM.YYYY")} </span>
          <Badge pill bg="primary" className="m-2">
            {patient.dob.fromNow(true)}
          </Badge>
        </p>
      </Card.Body>
      <Card.Footer>
        <Container fluid>
          <Button variant="warning" disabled={disabled || selected} onClick={onEdit} className="m-3">
            <Icon iconName="PencilSquare" size={18}/> Bearbeiten
          </Button>
          <Button variant="primary" disabled={disabled || selected} onClick={onPrint} className="m-3">
            <Icon iconName="PrinterFill" size={18}/> Drucken
          </Button>
        </Container>
      </Card.Footer>
    </Card>
  );
}

function newPatient(caseno, appSettings){
  return {
    surname: "",
    firstname: "",
    dob: new dayjs("Invalid Date"),
    sex: "",
    careof: "",
    street: "",
    housenumber: "",
    country: "DE",
    zip: "",
    city: "",
    phone: "",
    caseno: caseno,
    date: new dayjs(),
    time: new dayjs(),
    visitType: appSettings.visitType,
    poe: appSettings.poe,
    foe: appSettings.foe,
    insurance: "",
    insuranceNumber: "",
  };
}

function InputCasenoModal({onAdd, onCancel, oldCasenos}){
  const inputRef = useRef();

  useEffect(() => inputRef.current && inputRef.current.focus());

  return (
    <>
      <Modal
        show={true}
        onHide={onCancel}
        backdrop="static"
        keyboard={false}
      >
        <Formik
          validationSchema={Yup.object({
            caseno : Yup.string()
              .required("Falnummer erforderlich")
              .matches(/^\d+$/, "Fallnummer darf nur Ziffern enthalten")
              .matches(/^0*[1-9]\d{8}$/, "Fallnummer muß 9 Stellen haben (ohne führende Nullen)")
              .test(
                "nodupes", 
                "Fallnummer bereits verwendet. Bitte für jeden Fall einen neuen gelben Ausfallbogen scannen.",
                (value) => {return !oldCasenos.includes(value.replace(/^0+/, ""))}
              )

          })}
          onSubmit={(values, { setSubmitting }) => {
            onAdd(values.caseno.replace(/^0+/, ''));
          }}
          initialValues={{caseno:""}}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (    
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Neuen Fall Anlegen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Scanne eine neue Fallnummer von einem gelben Ausfallbogen um einen neuen Patienten anzulegen.</p>
              <FloatingLabel controlId={"floatingCaseno"} label="Fallnummer">
                <Form.Control 
                  label="Fallnummer"
                  type="input"
                  ref={inputRef}
                  value={values.caseno}
                  onChange={handleChange}
                  name="caseno"
                  onBlur={handleBlur}
                  isValid={touched.caseno && !errors.caseno}
                  isInvalid={touched.caseno && !!errors.caseno}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.caseno}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onCancel}>
                Abbrechen
              </Button>
              <Button variant="primary" type="submit">Fall anlegen</Button>
            </Modal.Footer>
          </Form>)}
        </Formik>
      </Modal>
    </>
  );
}

function AddPatientButton({onAdd, disabled, highlighted, background, oldCasenos}){
  let [isAdding, setIsAdding] = useState(false);

  return (
    <>
      <Button onClick={disabled?(()=>{}):()=>{setIsAdding(true)}} className={"add-patient-button" + (highlighted?" add-patient-button-highlight":"") + (background?" add-patient-button-background":"")}>
        <Icon size={32} iconName="PersonFillAdd"/> Neuer Fall
      </Button>
        {isAdding?
          <InputCasenoModal 
            onAdd={(caseno) => {setIsAdding(false); onAdd(caseno);}}
            onCancel={() => {setIsAdding(false);}}
            oldCasenos={oldCasenos}
          />:null
        }
      </>
  );
}

function InitialPanel(props){
  return (
    <Stack className="addpatient d-flex justify-content-around align-items-center">
      <AddPatientButton {...props} />
    </Stack>
  );

}

function PrintPatient({patient, onDone}){

  const blobURL = new RenderPDF(patient).toBlobURL();
  
  return (
    <Stack className="addpatient d-flex justify-content-around align-items-center">
      <Stack direction="horizontal" gap={5} className="d-flex justify-content-around align-items-center">
        <div>
          Lege zwei Seiten Klebchen und obendrauf eine Seite Papier in den Drucker und drucke untenstehendes PDF. Achte darauf in tatsächlicher Größe zu drucken (nicht "an Seitengröße anpassen" oder dergleichen).
        </div>
        <Button variant="success" onClick={onDone}>
          Fertig
        </Button>
      </Stack>
      <iframe className="print-frame" src={blobURL} title="Drucken"></iframe>
    </Stack>
  );
}

function SettingsPanel({onCancel, onSave, initial}) {
  
  let [appSettings, setAppSettings] = useState(initial);
  
  function handleChange(change){
    setAppSettings({
      ...appSettings,
      ...change
    })
  }

  return (
    <Card className="m-5">
      <Card.Header>
        <Card.Title>Einstellungen</Card.Title>
        <Card.Subtitle className="text-muted">
          Hier kannst du deine Grundeinstellungen anpassen, also die Werte die beim Anlegen eines neuen Falls automatisch eingestellt werden.
        </Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <Form>
          <Stack direction="horizontal" gap={3}>
            <FloatingLabel controlId="floatingVisitType" label="Besuchsart">
              <Form.Select
                placeholder=""
                defaultValue={appSettings.visitType}
                name="visitType"
                onChange={(e) => handleChange({visitType: e.target.value})}
                >
                {VisitTypes.map(vt => {
                  return (
                    <option value={vt} key={vt}>{vt}</option>
                  );
                })}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingOE" label="Pflegerische OE">
              <Form.Select
                placeholder=""
                defaultValue={appSettings.poe}
                name="poe"
                onChange={(e) => handleChange({poe: e.target.value})}
                >
                {POEs.map(oe => {
                  return (
                    <option value={oe} key={oe}>{oe}</option>
                  );
                })}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingFOE" label="Fachliche OE">
              <Form.Select
                placeholder=""
                defaultValue={appSettings.foe}
                name="foe"
                onChange={(e) => handleChange({foe: e.target.value})}
                >
                {FOEs.map(oe => {
                  return (
                    <option value={oe} key={oe}>{oe}</option>
                  );
                })}
              </Form.Select>
            </FloatingLabel>
          </Stack>
        </Form>
      </Card.Body>
      <Card.Footer>
        <Stack gap={3} direction="horizontal" className="justify-content-end">
          <Button variant="secondary" onClick={onCancel}>Abbrechen</Button>
          <Button variant="primary" onClick={() => {onSave(appSettings)}}>Speichern</Button>
        </Stack>
      </Card.Footer>
    </Card>
  );
}


function Main() {
  let [patients, setPatients] = useState([]);
  
  let [patientIdx, setPatientIdx] = useState(null)
  let [newCaseno, setNewCaseno] = useState("")
  let [appState, setAppState] = useState("initial")
  let [appSettings, setAppSettings] = useState({poe:"MZEN-EH",foe:"MAUFN",visitType:"1A"})

  function handleSaveAdd(patient) {
    setPatients([...patients, patient]);
    setAppState("initial")
  }

  function handleAdd(caseno){
    setNewCaseno(caseno);
    setAppState("adding");
  }

  function handleEdit(idx){
    setAppState("editing")
    setPatientIdx(idx);
  }

  function handleSaveEdit(patient){
    setPatients([
      ...patients.slice(0, patientIdx),
      patient,
      ...patients.slice(patientIdx+1)
    ]);
    setAppState("initial")
  }

  function handlePrint(idx){
    setPatientIdx(idx);
    setAppState("printing")
  }

  function handleDonePrint(){
    setAppState("initial")
  }

  function handleCancel(){
    setAppState("initial")
  }

  function handleEditSettings(){
    setAppState("editSettings");
  }

  function handleSaveSettings(settings){
    setAppSettings(settings);
    setAppState("initial")
  }
  
  return (
    <>
    <div className="bannerbar-container">
      <img
        src="/banner.png"
        className="bannerbar-image"
        alt=""
      />
      <div className="bannerbar-text">
        Notfallklebchen
      </div>
      <div className="bannerbar-icons">
        <Icon 
          iconName="Gear" 
          size={30} 
          className={"m-3"+(["initial", "printing"].includes(appState)?"":" text-muted")}
          onClick={
            ["initial", "printing"].includes(appState)? handleEditSettings : ()=>{}
          }
        />
      </div>
    </div>
    <Container fluid>
      <Row>
        <Col xs={3} className="sidebar">
          <Stack className="d-flex justify-content-around mt-3">
            <AddPatientButton 
              onAdd={handleAdd}
              disabled={appState === "editing" || appState === "adding"}
              highlighted={appState === "adding"}
              background={appState === "editing"}
              oldCasenos={patients.map((p) => {return p.caseno;})}
            />
          </Stack>
          {patients.map((patient, idx) => {
            console.log(patient)
            return (
              <PatientCard 
                key={idx} 
                patient={patient}
                disabled={appState === "adding" || (appState === "editing" && patientIdx !== idx)}
                selected={appState === "editing" && patientIdx === idx}
                onEdit={() => {handleEdit(idx)}}
                onPrint={() => {handlePrint(idx)}}
              />
            )
          }).reverse()}
        </Col>
        <Col xs={9} className="mainpanel">
          {appState === "adding"?
            <ChangePatient 
              patientInitial={newPatient(newCaseno, appSettings)}
              onSave={handleSaveAdd}
              onCancel={handleCancel}
            />:null}
          {appState === "editing"?
            <ChangePatient 
              patientInitial={patients[patientIdx]}
              onSave={handleSaveEdit}
              onCancel={handleCancel}
          />:null}
          {appState === "initial"?
            <InitialPanel
              onAdd={handleAdd}
              oldCasenos={patients.map((p) => {return p.caseno;})}
            />:null
          }
          {appState === "printing"?
            <PrintPatient
              patient={patients[patientIdx]}
              onDone={handleDonePrint}
            />:null
          }
          {appState === "editSettings"?
            <SettingsPanel
              initial={appSettings}
              onSave={handleSaveSettings}
              onCanel={handleCancel}
            />:null
          }
        </Col>
      </Row>
    </Container>
    </>
  )
}

function App() {

  return (
    <div className="App h100">
      <Main />
    </div>
  );
}

export default App;

import { jsPDF } from "jspdf";
import { interleaved2of5, qrcode } from 'bwip-js';
import './Roboto-Condensed-normal';
import './Roboto-Condensed-bold';

const STICKER_L_WIDTH = 55;
const STICKER_L_HEIGHT = 38;
const STICKER_L_MARGIN_X = 11;
const STICKER_L_MARGIN_Y = 10;
const STICKER_L_POSITIONS = [[1,0],[2,0],[3,0],[0,1],[1,1],[2,1],[3,1],[4,1],[0,2],[1,2],[2,2],[3,2],[4,2],[0,3],[1,3],[2,3],[3,3],[4,3],[1,4],[2,4],[3,4]];

const STICKER_M_HEIGHT = 14;
const STICKER_M_WIDTH = 55;
const STICKER_M_MARGIN_X = 11;
const STICKER_M_MARGIN_Y = 6;
const STICKER_M_POSITIONS = [[0,0],[4,5 + 3*STICKER_L_HEIGHT/STICKER_M_HEIGHT]]

const STICKER_S_HEIGHT = 14;
const STICKER_S_WIDTH = 27.5;
const STICKER_S_MARGIN_X = 11;
const STICKER_S_MARGIN_Y = 6;
const STICKER_S_POSITIONS = [
    // upper left
    [0,1],[1,1],[0,2],[1,2],
    // upper right
    [8,0],[9,0],[8,1],[9,1],[8,2],[9,2],
    // lower left
    [0,3+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    [1,3+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    [0,4+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    [1,4+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    [0,5+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    [1,5+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    // lower right
    [8,3+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    [9,3+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    [8,4+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
    [9,4+3*STICKER_L_HEIGHT/STICKER_M_HEIGHT],
]


class RenderPDF {
    constructor(patient){
        // init
        this.patient = patient;
        this.barcode = null;
        this.doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true});
        
        // generate pages
        this.addSummaryPage();
        this.addStickerPage();
        this.addStickerPage();
    }
    
    formatDate(date){
        return date.format("DD.MM.YYYY");
    }

    formatTime(time){
        return time.format("HH:mm");
    }

    longSex(sex){
        switch(sex){
            case "m" : return "männlich";
            case "w" : return "weiblich";
            case "u" : return "unbekannt";
            default : return sex;
        }
    }

    generateBarcode(){
        if( this.barcode === null ){
            let canvas = document.createElement("canvas");
            this.barcode = interleaved2of5(canvas, {
                scale: 10,
                height: 15,
                includetext: false,
                text: this.patient.caseno + "0",
            }).toDataURL();
        }
        return this.barcode;
    }

    generateQR(text){
        let canvas = document.createElement("canvas");
        return qrcode(canvas, {
                scale: 15,
                width: 15,
                height: 15,
                includetext: false,
                text: text,
            }).toDataURL();
    }

    generateSAPQR(){
        let text = (
            this.patient.surname + "\t\t" +
            this.patient.firstname + "\t\t\t\t" +
            this.formatDate(this.patient.dob) + "\t\t" +
            this.patient.sex + "\t\t\t\t\t\t"+
            this.patient.careof + "\t" +
            this.patient.street + "\t" +
            this.patient.housenumber + "\t\t" +
            this.patient.country + "\t" +
            this.patient.zip + "\t"+
            this.patient.city + "\t\t\t" +
            this.patient.phone + " ");
        return this.generateQR(text);
    }

    generateSAPCaseQR(){
        const text = (
            this.patient.visitType + "\t" +
            this.formatDate(this.patient.date).replaceAll(".", "") + "\t" +
            this.formatTime(this.patient.time) + "\t\t\t\t\t\t\t\t\t" +
            this.patient.poe + (this.patient.poe.length < 8? "\t\t" : "") +
            this.patient.foe + "\t"
        );
        return this.generateQR(text)

    }

    generateEcareQR(){
        let text = (
            this.patient.surname + "\t"+
            this.patient.firstname + "\t" +
            this.patient.street + "\t" + 
            this.patient.housenumber + "\t\t" +
            this.patient.zip + "\t" +
            this.patient.city + "\t\t\t" +
            this.patient.phone + "\t\t\t\t\t\t" +
            this.formatDate(this.patient.dob) + "\t\t\t\t\t\t\t\t\t" +
            "\b".repeat(this.patient.surname.length)
        );
        return this.generateQR(text);
    }

    addSummaryPage(){
        // title
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(18);
        this.clippedTextBox("SAP-Ausfall Aufnahmebogen", 10, 10, 100)

        // visit type
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(32);
        this.clippedTextBox(this.patient.visitType, 10, 22.5, 20)
        
        // oes
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(12);
        this.clippedTextBox(this.patient.poe, 28, 23, 40);
        this.clippedTextBox(this.patient.foe, 28, 29, 40);
        

        // date and time
        this.clippedTextBox(this.formatDate(this.patient.date), 93, 23, 40);
        this.clippedTextBox(this.formatTime(this.patient.time), 98, 29, 40);

        // barcode
        this.doc.addImage(this.generateBarcode(), 'PNG', 160, 13, 40, 17);
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(9);
        this.clippedTextBox(`Fall: ${this.patient.caseno}`, 169, 31, 35);

        // title end
        this.doc.line(10, 35, 200, 35).stroke();
        
        // patient data
        // ... labels
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(12);
        this.clippedTextBox("Nachname", 10, 46, 30);
        this.clippedTextBox("Vorname", 10, 53, 30);
        this.clippedTextBox("Geburstdatum", 10, 60, 30);
        this.clippedTextBox("Geschlecht", 10, 67, 30);

        // ... values
        this.doc.setFont("Roboto-Condensed", "bold");
        this.clippedTextBox(this.patient.surname, 40, 46, 80);
        this.clippedTextBox(this.patient.firstname, 40, 53, 80);
        this.clippedTextBox(this.formatDate(this.patient.dob), 40, 60, 80);
        this.clippedTextBox(this.longSex(this.patient.sex), 40, 67, 80);

        // insurance data
        this.doc.setFont("Roboto-Condensed", "normal");
        this.clippedTextBox("Versicherung", 123, 46, 77);
        this.clippedTextBox("Versichertennummer", 123, 60, 77);

        this.doc.setFont("Roboto-Condensed", "bold");
        this.clippedTextBox(this.patient.insurance, 123, 53, 77);
        this.clippedTextBox(this.patient.insuranceNumber, 123, 67, 77);

        // adress
        // ... labels
        this.doc.setFont("Roboto-Condensed", "normal");
        this.clippedTextBox("Anschrift", 10, 78, 77);
        this.clippedTextBox("c/o", 10, 85, 10);
        this.clippedTextBox("Telefon", 123, 78, 30);
        
        // ... values
        this.doc.setFont("Roboto-Condensed", "bold");
        this.clippedTextBox(this.patient.careof, 20, 85, 100);
        this.clippedTextBox(`${this.patient.street} ${this.patient.housenumber}`, 10, 92, 190);
        this.clippedTextBox(`${this.patient.country} - ${this.patient.zip} ${this.patient.city}`, 10, 99, 190);
        this.clippedTextBox(this.patient.phone, 123, 85, 77);

        // add sap qr code
        // ... code
        this.doc.addImage(this.generateSAPQR(), 'PNG', 10, 110, 85, 85);
        // ... title
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(18);
        this.clippedTextBox("SAP QR-Code", 105, 110, 50);
        // ... instructions
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(12);
        this.doc.text("Scanne den linken, großen QR-Code mit einem geeigneten Scanner, um die Patientendaten später ins SAP zurück zu übertragen. Gehe erst in den Fall und dort mit dem Cursor in das Feld \"Nachname\". Scanne dann den Code und entferne anschließend die \"0\" vor dem Nachnamen. Um die Besuchsdaten zurück zu übertragen, scanne den kleinen, unteren QR-Code.", 105, 120, {
            baseline: "top",
            maxWidth: 95,
        });
        
        this.doc.addImage(this.generateSAPCaseQR(), 'PNG', 165, 160, 35, 35);

        // add ecare qr code
        // ... code
        this.doc.addImage(this.generateEcareQR(), 'PNG', 10, 205, 85, 85);
        // ... title
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(18);
        this.clippedTextBox("Ecare QR-Code", 105, 205, 50);
        // ... instructions
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(12);
        this.doc.text("Scanne diesen QR-Code mit einem geeigneten Scanner, um die Patientendaten ins Ecare zu übertragen. Lege erst einen neuen Fall an indem du auf das \"+\" mit rotem Fragezeichen oben links im Ecare klickst. Wähle dann temporäre Nummer und scanne die Fallnummer oben rechts auf diesem Bogen. Im sich daraufhin öffnenden Dossier, klicke auf den Namen (\"Unbekannt XX\") oben links. Scanne dann den QR-Code um die Patientendaten automatisch ausfüllen zu lassen. Das Geschlecht muß in Ecare leider manuell eingetragen werden.", 105, 215, {
            baseline: "top",
            maxWidth: 95,
        });
        
    }

    addStickerPage(){
        this.doc.addPage("a4", "l");
        // this.addSticker(10, 10);
        STICKER_L_POSITIONS.forEach(([x, y]) => {
            this.addStickerL(x*STICKER_L_WIDTH + STICKER_L_MARGIN_X, y*STICKER_L_HEIGHT + STICKER_L_MARGIN_Y);
        });
        STICKER_M_POSITIONS.forEach(([x, y]) => {
            this.addStickerM(x*STICKER_M_WIDTH + STICKER_M_MARGIN_X, y*STICKER_M_HEIGHT + STICKER_M_MARGIN_Y);
        });
        STICKER_S_POSITIONS.forEach(([x, y]) => {
            this.addStickerS(x*STICKER_S_WIDTH + STICKER_S_MARGIN_X, y*STICKER_S_HEIGHT + STICKER_S_MARGIN_Y);
        });

    }

    addStickerL(x, y){
        // this.doc.rect(x, y, STICKER_L_WIDTH, STICKER_L_HEIGHT, 'S');
        // name
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(10);
        this.clippedTextBox(this.patient.surname, x+3, y+3, 49);
        this.doc.setFontSize(8);
        this.clippedTextBox(this.patient.firstname, x+3, y+7, 31.5);
        
        // sex, dob
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(8);
        this.clippedTextBox(`${this.patient.sex.toUpperCase()}*${this.formatDate(this.patient.dob)}`, x+35, y+7, 17);
        
        // adress
        this.clippedTextBox(`${this.patient.zip} ${this.patient.city}`, x+3, y+10, 31.5);
        this.clippedTextBox(`${this.patient.street} ${this.patient.housenumber}`, x+3, y+13, 31.5);
        
        // date, time
        this.clippedTextBox(`Aufn.: ${this.formatDate(this.patient.date)} ${this.formatTime(this.patient.time)}`, x+3, y+16, 31.5);

        // insurance
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(6);
        this.clippedTextBox(`V: ${this.patient.insurance}`, x+35, y+10.25, 17);
        this.clippedTextBox(`№: ${this.patient.insuranceNumber}`, x+35, y+13.25, 17);

        // case data
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(9);
        this.clippedTextBox(this.patient.visitType, x+3, y+19, 4.5);
        this.doc.setFont("Roboto-Condensed", "normal");
        this.clippedTextBox(`${this.patient.foe} ${this.patient.poe}`, x+8, y+19, 42.5);

        // barcode
        this.doc.addImage(this.generateBarcode(), 'PNG', x+12.5, y+23, 30, 10);
        
        // casenumber
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(7);
        this.clippedTextBox(`Fall: ${this.patient.caseno}`, x+20, y+33, 17);
        
        this.doc.rect(x+2, y+2, STICKER_L_WIDTH-4, 20.5, 'S');
        
    }

    addStickerM(x, y){
        // this.doc.rect(x, y, STICKER_M_WIDTH, STICKER_M_HEIGHT, 'S');

        // name
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(7);
        this.clippedTextBox(this.patient.surname, x+2, y+1.5, 51);
        this.doc.setFontSize(7);
        this.clippedTextBox(this.patient.firstname, x+2, y+4, 51);        

        //dob, sex
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(8);
        this.clippedTextBox(`${this.patient.sex.toUpperCase()} *${this.formatDate(this.patient.dob)}`, x+2, y+6.75, 23.5);

        //caseno
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(9);
        this.clippedTextBox(`Fall: ${this.patient.caseno}`, x+2, y+10, 23.5);

        // barcode
        this.doc.addImage(this.generateBarcode(), 'PNG', x+26, y+6.75, 27, 5.75);

    }

    addStickerS(x, y){
        // this.doc.rect(x, y, STICKER_S_WIDTH, STICKER_S_HEIGHT, 'S');
        
        // name
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(7);
        this.clippedTextBox(this.patient.surname, x+2, y+1.5, 23.5);
        this.doc.setFontSize(7);
        this.clippedTextBox(this.patient.firstname, x+2, y+4, 13);        

        // oe
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(6);
        this.clippedTextBox(this.patient.poe, x+15.5, y+4.25, 10);
        
        //dob, sex
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(8);
        this.clippedTextBox(`${this.patient.sex.toUpperCase()} *${this.formatDate(this.patient.dob)}`, x+2, y+6.75, 23.5);

        //caseno
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(9);
        this.clippedTextBox(`Fall: ${this.patient.caseno}`, x+2, y+10, 23.5);

    }


    clippedTextBox(text, x, y, w){
        let clippedText = text;

        if(this.doc.getTextWidth(clippedText) > w) {
            while(this.doc.getTextWidth(clippedText + "…") > w || clippedText === ""){
                clippedText = clippedText.slice(0, -1);
            }
            if(clippedText !== "") {
                clippedText += "…";
            }
        }
        this.doc.text(clippedText, x, y, {baseline:"top"});
        // this.doc.line(x, y, x+w, y).stroke();
    }

    toDataURIString(){
        return this.doc.output('datauristring')
    }

    toBlobURL(){
        const pdf = new Blob([this.doc.output("blob")], {type: "application/pdf"})
        return URL.createObjectURL(pdf);
    }
}

export default RenderPDF;